import React from "react";
import { iosArrowRight } from "../../../util/ui_util";
import { useTranslation } from "react-i18next";

export const ItemNavigationDrawer = ({ category, isRtl, onClick }) => {
  const hasSubcategories =
    category?.categories && category.categories.length > 0;
    const {t} = useTranslation();

  const handleClick = () => {
    onClick(category);
  };

  return (
    <div className="w-full" onClick={handleClick}>
      <div className="flex flex-row justify-between items-center w-full cursor-pointer pb-3 px-4">
        <div className="text-gray-500">{t(category?.name)}</div>
        {hasSubcategories && (
          <div className={`transition-transform ${isRtl ? "rotate-180" : ""}`}>
            <span className="text-gray-300">{iosArrowRight()}</span>
          </div>
        )}
      </div>
      <hr className="border-t border-gray-100" />
    </div>
  );
};

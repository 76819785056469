import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";
import { AppContext } from "../../../context/app/AppState";

export const LowerSchoolPage = () => {
  const { t } = useTranslation();
  const { primaryColor, accentColor } = useContext(AppContext);

  return (
    <InfoPageLayout
      imageUrl={AppPages.LowerSchoolPage.image}
      header={t(AppPages.LowerSchoolPage.name)}
      subheader={t("lowerSchoolPageSubheader")}
      pages={[AppPages.MiddleSchoolPage, AppPages.UpperSchoolPage]}
      children={
        <div className="mt-8">
          <p className="text-textLight dark:text-textDark">
            {t("lowerSchoolText1")}
          </p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("lowerSchoolText2")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p className="text-textLight dark:text-textDark mt-4">
            {t("lowerSchoolText3")}
          </p>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("lowerSchoolText4") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("lowerSchoolText5")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("lowerSchoolText6") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("lowerSchoolText7")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("lowerSchoolText8") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("lowerSchoolText9")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("lowerSchoolText10") }}
          ></p>
        </div>
      }
    />
  );
};

import React, { useContext } from "react";
import iconPdf from "../../../../assets/images/ic_pdf.svg";
import { AppContext } from "../../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { basePadding, iconDownload } from "../../../../util/ui_util";

export const ItemDocument = ({ document }) => {
  const { t } = useTranslation();
  const { primaryColor, accentColor } = useContext(AppContext);

  return (
    <div>
      <div
        className={`w-ful flex flex-row justify-between items-center ${basePadding} md:px-0`}
      >
        <div className="flex flex-row items-center space-x-2 rtl:space-x-reverse">
          <img src={iconPdf} alt="" className="w-[24px] md:w-[32px]" />
          <div className="md:text-xl" style={{ color: primaryColor }}>
            {document.name}
          </div>
        </div>
        <div className="w-2"></div>
        <a
          className="flex flex-row items-center space-x-2 rtl:space-x-reverse select-none"
          href={document.url}
          target="_blank"
          rel="noreferrer"
        >
          <div
            className="text-sm md:text-base font-bold"
            style={{ color: primaryColor }}
          >
            {t("download")}
          </div>
          {iconDownload({
            fillColor: primaryColor,
            width: "24px",
            height: "24px",
          })}
        </a>
      </div>
      <div
        className="w-full h-[1px] mt-4"
        style={{ backgroundColor: accentColor }}
      ></div>
    </div>
  );
};

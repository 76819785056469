import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const AdmissionsProcessPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.AdmissionsProcessPage.image}
      header={t(AppPages.AdmissionsProcessPage.name)}
      subheader={t("admissionsProcessPageSubheader")}
      text={t("admissionsProcessText")}
      pages={[
        AppPages.AdmissionsOverviewPage,
        AppPages.AgeRequirementsGradePlacementsPage,
      ]}
      isHtml={true}
    />
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const OurTransportPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.OurTransportPage.image}
      header={t(AppPages.OurTransportPage.name)}
      subheader={t("ourTransportPageSubheader")}
      text={t("ourTransportText")}
      isHtml={true}
      pages={[AppPages.OurClinicPage, AppPages.SchoolUniformPage]}
    />
  );
};

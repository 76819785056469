import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const WhyAcademicPerformancePage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.WhyAcademicPerformancePage.image}
      header={t(AppPages.WhyAcademicPerformancePage.name)}
      subheader={""}
      text={t("whyAcademicPerformanceText")}
      pages={[AppPages.WhyUniversityDestinationsPage, AppPages.HighPerformanceLearningPage]}
      isHtml={true}
    />
  );
};

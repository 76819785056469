import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ItemNavigationDrawer } from "./items/ItemNavigationDrawer";
import { useTranslation } from "react-i18next";
import { findParentCategory } from "../../util/util";
import { iconClock, iosArrowRight } from "../../util/ui_util";

export const Drawer = ({ isOpen, isRtl, allCategories, onCloseClick }) => {
  const [categories, setCategories] = useState(allCategories);
  const [drawerCategory, setDrawerCategory] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setCategories(allCategories);
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm z-40"
          onClick={onCloseClick}
        ></div>
      )}
      <div
        className={`fixed top-0 ${
          isRtl ? "right-0" : "left-0"
        } bottom-0 z-50 bg-white w-[70vw] md:w-1/2  py-4 space-y-4 flex flex-col transform transition-transform duration-300 ${
          isOpen
            ? isRtl
              ? "translate-x-0"
              : "translate-x-0"
            : isRtl
            ? "translate-x-full"
            : "-translate-x-full"
        }`}
        dir={isRtl ? "rtl" : "ltr"}
      >
        <div className="flex flex-row justify-between items-center w-full px-4">
          <div className="flex flex-row space-x-1 items-center">
            <div
              className={`${drawerCategory ? "block" : "hidden"} ${
                isRtl ? "" : "rotate-180"
              } cursor-pointer`}
              onClick={() => {
                const parent = findParentCategory(
                  allCategories,
                  drawerCategory.id
                );
                if (!parent) {
                  setDrawerCategory(null);
                  setCategories(allCategories);
                  return;
                }
                setDrawerCategory(parent);
                setCategories(parent.categories);
              }}
            >
              {iosArrowRight()}
            </div>
            <div className="font-bold">
              {drawerCategory ? t(drawerCategory.name) : t("mainMenu")}
            </div>
          </div>
          <button
            onClick={onCloseClick}
            className="text-red-600 focus:outline-none"
          >
            {iconClock()}
          </button>
        </div>
        <hr className="border-t border-gray-400" />
        <div className="flex flex-col space-y-4">
          {categories.map((category) => (
            <ItemNavigationDrawer
              key={category.id}
              category={category}
              isRtl={isRtl}
              onClick={(c) => {
                if (!c.categories || c.categories.length === 0) {
                  navigate(`/category/${category.id}`);
                  return;
                }
                setDrawerCategory(c);
                setCategories(c.categories);
              }}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const StudentLifePage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.StudentLifePage.image}
      header={t(AppPages.StudentLifePage.name)}
      subheader={t("studentLifePageSubheader")}
      text={t("studentLifeText")}
      isHtml={true}
    />
  );
};

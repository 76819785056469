import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";

export const ItemPageWithImage = ({ page }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <Link to={page.route} draggable={false}>
      <div
        className="cursor-pointer w-full lg:w-[400px]"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <img
          src={page.image}
          alt=""
          className={`w-full lg:h-[400px] object-cover transition-transform duration-300 ${
            isHovered ? "scale-110" : "scale-100"
          }`}
          draggable={false}
        />
        <h4 className="font-bold text-xl md:text-2xl mt-8" style={{ color: primaryColor }}>
          {t(page.name)}
        </h4>
        <p className="text-textLight dark:text-textDark mt-4">
          {t(page.description)}
        </p>
      </div>
    </Link>
  );
};

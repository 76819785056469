import React from "react";
import { useTranslation } from "react-i18next";
import { AppPages } from "../../../util/app_pages";
import { InfoPageLayout } from "../../base/InfoPageLayout";

export const AboutUsPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.AboutUsPage.image}
      header={t("aboutUsPageHeader")}
      subheader={t("aboutUsPageSubheader")}
      text={t("aboutUsText")}
      pages={[
        AppPages.WelcomeMessagePage,
        AppPages.VisionMissionValuesPage,
        AppPages.CampusFacilitiesPage,
      ]}
    />
  );
};

import React, { useContext } from "react";
import { AppContext } from "../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { basePadding, PurpleDivider } from "../../util/ui_util";
import { Layout } from "./Layout";
import { HomeButtons } from "../pages/home/HomeButtons";
import { ItemPageWithImage } from "./items/ItemPageWithImage";

export const InfoPageLayout = ({
  imageUrl,
  header,
  subheader,
  children,
  text,
  pages,
  isHtml = false,
}) => {
  const { primaryColor, accentColor } = useContext(AppContext);
  const { t } = useTranslation();

  return (
    <Layout>
      <div className="w-full bg-backgroundLight dark:bg-backgroundDark py-8">
        <div
          className={`flex flex-col-reverse ${basePadding}  md:container md:mx-auto md:px-0 md:flex-row md:space-x-4 rtl:space-x-reverse`}
        >
          <div className="md:w-1/2">
            <h2
              className="font-bold text-3xl md:text-5xl mt-8"
              style={{ color: primaryColor }}
            >
              {header}
            </h2>
            <div className="mt-8">
              <PurpleDivider />
            </div>
            <h2
              className="font-bold text-2xl md:text-3xl mt-8"
              style={{ color: accentColor }}
            >
              {subheader}
            </h2>
            {children ? (
              children
            ) : isHtml ? (
              <p
                className="text-textLight dark:text-textDark mt-4"
                dangerouslySetInnerHTML={{ __html: text }}
              ></p>
            ) : (
              <p className="text-textLight dark:text-textDark mt-4">{text}</p>
            )}
          </div>
          <img
            src={imageUrl}
            alt=""
            className="w-full md:w-1/2 md:h-[600px] object-scale-down"
          />
        </div>
        {pages && (
          <h4
            className="font-bold text-2xl md:text-4xl w-full text-center mt-8 md:mt-16"
            style={{ color: primaryColor }}
          >
            {t("moreToExplore")}
          </h4>
        )}
        <div
          className={`grid grid-cols-2 gap-x-2 gap-y-8 md:flex md:container md:mx-auto md:gap-x-0 md:gap-y-0 md:flex-nowrap md:flex-row ${basePadding} md:px-0 pt-8 ${
            pages?.length === 3 ? "md:justify-between" : "md:justify-evenly"
          }`}
        >
          {pages?.map((page, index) => (
            <ItemPageWithImage key={index} page={page} />
          ))}
        </div>

        <HomeButtons />
      </div>
    </Layout>
  );
};

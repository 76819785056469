import { Route, Routes } from "react-router-dom";
import useScrollToTop from "./hooks/useScrollToTop";
import CombinedProviders from "./context/CombinedProviders";
import { HomePage } from "./components/pages/home/HomePage";
import useDarkMode from "./hooks/useDarkMode";
import { AppPages } from "./util/app_pages";
import { AboutUsPage } from "./components/pages/about-us/AboutUsPage";
import { WelcomeMessagePage } from "./components/pages/welcome-message/WelcomeMessagePage";
import { VisionMissionValuesPage } from "./components/pages/vision-mission-values/VisionMissionValuesPage";
import { CampusFacilitiesPage } from "./components/pages/campus-facilities/CampusFacilitiesPage";
import { WhyAcademicPerformancePage } from "./components/pages/why-academic-performance/WhyAcademicPerformancePage";
import { WhyUniversityDestinationsPage } from "./components/pages/why-university-destinations/WhyUniversityDestinationsPage";
import { HighPerformanceLearningPage } from "./components/pages/high-performance-learning/HighPerformanceLearningPage";
import { AdmissionsOverviewPage } from "./components/pages/admissions-overview/AdmissionsOverviewPage";
import { AdmissionsProcessPage } from "./components/pages/admissions-process/AdmissionsProcessPage";
import { StudentLifePage } from "./components/pages/student-life/StudentLifePage";
import { OurClinicPage } from "./components/pages/our-clinic/OurClinicPage";
import { OurTransportPage } from "./components/pages/our-transport/OurTransportPage";
import { SchoolUniformPage } from "./components/pages/school-uniform/SchoolUniformPage";
import { LowerSchoolPage } from "./components/pages/lower-school/LowerSchoolPage";
import { MiddleSchoolPage } from "./components/pages/middle-school/MiddleSchoolPage";
import { UpperSchoolPage } from "./components/pages/upper-school/UpperSchoolPage";
import { KeyDocumentsPoliciesPage } from "./components/pages/key-documents-policies/KeyDocumentsPoliciesPage";
import { AcademicCalenderPage } from "./components/pages/academic-calender/AcademicCalenderPage";
import { InspectionReportsPage } from "./components/pages/inspection-reports/InspectionReportsPage";
import { AgeRequirementGradePlacementsPage } from "./components/pages/age-requirements-grade-placements/AgeRequirementGradePlacementsPage";
import { EventsPage } from "./components/pages/events/EventsPage";

function App() {
  useScrollToTop();
  useDarkMode();
  return (
    <CombinedProviders>
      <Routes>
        <Route path={AppPages.HomePage.route} element={<HomePage />} />
        <Route path={AppPages.AboutUsPage.route} element={<AboutUsPage />} />
        <Route
          path={AppPages.WelcomeMessagePage.route}
          element={<WelcomeMessagePage />}
        />
        <Route
          path={AppPages.VisionMissionValuesPage.route}
          element={<VisionMissionValuesPage />}
        />
        <Route
          path={AppPages.CampusFacilitiesPage.route}
          element={<CampusFacilitiesPage />}
        />
        <Route
          path={AppPages.WhyAcademicPerformancePage.route}
          element={<WhyAcademicPerformancePage />}
        />
        <Route
          path={AppPages.WhyUniversityDestinationsPage.route}
          element={<WhyUniversityDestinationsPage />}
        />
        <Route
          path={AppPages.HighPerformanceLearningPage.route}
          element={<HighPerformanceLearningPage />}
        />
        <Route
          path={AppPages.AdmissionsOverviewPage.route}
          element={<AdmissionsOverviewPage />}
        />
        <Route
          path={AppPages.AdmissionsProcessPage.route}
          element={<AdmissionsProcessPage />}
        />
        <Route
          path={AppPages.StudentLifePage.route}
          element={<StudentLifePage />}
        />
        <Route
          path={AppPages.OurClinicPage.route}
          element={<OurClinicPage />}
        />
        <Route
          path={AppPages.OurTransportPage.route}
          element={<OurTransportPage />}
        />
        <Route
          path={AppPages.SchoolUniformPage.route}
          element={<SchoolUniformPage />}
        />
        <Route
          path={AppPages.LowerSchoolPage.route}
          element={<LowerSchoolPage />}
        />
        <Route
          path={AppPages.MiddleSchoolPage.route}
          element={<MiddleSchoolPage />}
        />
        <Route
          path={AppPages.UpperSchoolPage.route}
          element={<UpperSchoolPage />}
        />
        <Route
          path={AppPages.KeyDocumentsPoliciesPage.route}
          element={<KeyDocumentsPoliciesPage />}
        />
        <Route
          path={AppPages.AcademicCalenderPage.route}
          element={<AcademicCalenderPage />}
        />
        <Route
          path={AppPages.InspectionReportsPage.route}
          element={<InspectionReportsPage />}
        />
        <Route
          path={AppPages.AgeRequirementsGradePlacementsPage.route}
          element={<AgeRequirementGradePlacementsPage />}
        />
        <Route path={AppPages.EventsPage.route} element={<EventsPage />} />
      </Routes>
    </CombinedProviders>
  );
}

export default App;

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const UpperSchoolPage = () => {
  const { t } = useTranslation();
  const { primaryColor, accentColor } = useContext(AppContext);

  return (
    <InfoPageLayout
      imageUrl={AppPages.UpperSchoolPage.image}
      header={t(AppPages.UpperSchoolPage.name)}
      subheader={t("upperSchoolPageSubheader")}
      pages={[AppPages.LowerSchoolPage, AppPages.MiddleSchoolPage]}
      children={
        <div className="mt-8">
          <p className="text-textLight dark:text-textDark">
            {t("upperSchoolText1")}
          </p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("upperSchoolText2")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p className="text-textLight dark:text-textDark mt-4">
            {t("upperSchoolText3")}
          </p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("upperSchoolText4")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("upperSchoolText5") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("upperSchoolText6")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("upperSchoolText7") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("upperSchoolText8")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("upperSchoolText9") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("upperSchoolText10")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("upperSchoolText11") }}
          ></p>
        </div>
      }
    />
  );
};

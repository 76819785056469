import imageAboutOurSchool from "../assets/images/about_our_school.jpg";
import imageWelcomeMessage from "../assets/images/home_welcome_message.png";
import imageVisionMissionValues from "../assets/images/vision_mission_values.jpg";
import imageCampusFacilities from "../assets/images/campus-facilities.jpg";
import imageAcademicPerformance from "../assets/images/academic_performance.jpg";
import imageUniversityDestinations from "../assets/images/university_destinations.jpg";
import imageHighPerformanceLearning from "../assets/images/high_performance_learning.jpg";
import imageAdmissionsOverview from "../assets/images/admissions_overview.jpg";
import imageAdmissionsProcess from "../assets/images/admissions_process.jpg";
import imageLowerSchool from "../assets/images/lower_school.jpg";
import imageMiddleSchool from "../assets/images/middle_school.jpg";
import imageUpperSchool from "../assets/images/upper_school.jpg";
import imageStudentLife from "../assets/images/student_life.jpg";
import imageOurClinic from "../assets/images/our_clinic.jpg";
import imageOurTransport from "../assets/images/our_transport.jpg";
import imageUniform from "../assets/images/uniform.jpg";
import imageAgeRequirements from "../assets/images/age_requirements.jpg";

export const AppPages = {
  HomePage: {
    route: "/",
    name: "homePage",
    image: "",
    description: "",
  },
  AboutUsPage: {
    route: "/about-us",
    name: "aboutUsPage",
    image: imageAboutOurSchool,
    description: "aboutUsPageDescription",
  },
  WelcomeMessagePage: {
    route: "/welcome-message",
    name: "welcomeMessagePage",
    image: imageWelcomeMessage,
    description: "welcomeMessagePageDescription",
  },
  VisionMissionValuesPage: {
    route: "/vision-mission-values",
    name: "visionMissionValuesPage",
    image: imageVisionMissionValues,
    description: "visionMissionValuesPageDescription",
  },
  CampusFacilitiesPage: {
    route: "/campus-facilities",
    name: "campusFacilitiesPage",
    image: imageCampusFacilities,
    description: "campusFacilitiesPageDescription",
  },
  WhyAcademicPerformancePage: {
    route: "/why-gms-academic-performance",
    name: "whyAcademicPerformancePage",
    image: imageAcademicPerformance,
    description: "whyAcademicPerformancePageDescription",
  },
  WhyUniversityDestinationsPage: {
    route: "/why-gms-university-destinations",
    name: "whyUniversityDestinationsPage",
    image: imageUniversityDestinations,
    description: "whyUniversityDestinationsPageDescription",
  },
  HighPerformanceLearningPage: {
    route: "/high-performance-learning",
    name: "highPerformanceLearningPage",
    image: imageHighPerformanceLearning,
    description: "highPerformanceLearningPageDescription",
  },
  AdmissionsOverviewPage: {
    route: "/admissions-overview",
    name: "admissionsOverviewPage",
    image: imageAdmissionsOverview,
    description: "admissionsOverviewPageDescription",
  },
  AdmissionsProcessPage: {
    route: "/admissions-process",
    name: "admissionsProcessPage",
    image: imageAdmissionsProcess,
    description: "admissionsProcessPageDescription",
  },
  StudentLifePage: {
    route: "/student-life",
    name: "studentLifePage",
    image: imageStudentLife,
    description: "studentLifePageDescription",
  },
  OurClinicPage: {
    route: "/our-clinic",
    name: "ourClinicPage",
    image: imageOurClinic,
    description: "ourClinicPageDescription",
  },
  OurTransportPage: {
    route: "/our-transport",
    name: "ourTransportPage",
    image: imageOurTransport,
    description: "ourTransportPageDescription",
  },
  SchoolUniformPage: {
    route: "/school-uniform",
    name: "schoolUniformPage",
    image: imageUniform,
    description: "schoolUniformPageDescription",
  },
  LowerSchoolPage: {
    route: "/lower-school",
    name: "lowerSchoolPage",
    image: imageLowerSchool,
    description: "lowerSchoolPageDescription",
  },
  MiddleSchoolPage: {
    route: "/middle-school",
    name: "middleSchoolPage",
    image: imageMiddleSchool,
    description: "middleSchoolPageDescription",
  },
  UpperSchoolPage: {
    route: "/upper-school",
    name: "upperSchoolPage",
    image: imageUpperSchool,
    description: "upperSchoolPageDescription",
  },
  KeyDocumentsPoliciesPage: {
    route: "/key-documents-policies",
    name: "keyDocumentsPoliciesPage",
    image: null,
    description: "keyDocumentsPoliciesPageDescription",
  },
  AcademicCalenderPage: {
    route: "/academic-calender",
    name: "academicCalenderPage",
    image: null,
    description: "academicCalenderPageDescription",
  },
  InspectionReportsPage: {
    route: "/inspection-reports",
    name: "inspectionReportsPage",
    image: null,
    description: "inspectionReportsPageDescription",
  },
  AgeRequirementsGradePlacementsPage: {
    route: "/age-requirements-grade-placements",
    name: "ageRequirementsGradePlacementsPage",
    image: imageAgeRequirements,
    description: "ageRequirementsGradePlacementsPageDescription",
  },
  EventsPage: {
    route: "/events",
    name: "eventsPage",
    image: "",
    description: "",
  },
};

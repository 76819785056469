import { AppPages } from "./app_pages";
import imageHomeLowerSchool from "../assets/images/home_lower_school.jpg";
import imageHomeMiddleSchool from "../assets/images/home_middle_school.jpg";
import imageHomeUpperSchool from "../assets/images/home_upper_school.jpg";
import iconFacebook from "../assets/images/icon_facebook.png";
import iconInstagram from "../assets/images/icon_instagram.png";
import iconTwitter from "../assets/images/icon_twitter.png";
import event1Video from "../assets/videos/event1.MP4";

export const dummyBannerData = [
  {
    id: 1,
    text: "Breaking News: New React features released!",
    url: "https://google.com",
  },
  {
    id: 2,
    text: "Update: Tailwind CSS v3.0 is now available!",
    url: "https://google.com",
  },
  {
    id: 3,
    text: "Announcement: Join our webinar on modern web development!",
    url: "https://google.com",
  },
];

export const homeSliderImages = [
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
  "https://cdn.salla.sa/form-builder/IwZx4hDmrHu6FAT8BcCpC20KD8BfXMyMM5gWNz71.jpg",
];

export const dummyFooterPartners = [
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_design-images/wis_logo.svg?h=100&w=300&hash=53784312C078EEDC72C9C91AFA5C0286",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/shared/client-img-3.jpg?h=83&w=201&hash=1584E8311D259951929F8C7657526BD9",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/shared/_shared-footer-logos/cobis_accredited_member_logo_no-margin.svg?h=85&w=170&hash=6D91F407D5EBB9E02A4CDA46CA9630D0",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/misc/bso_wis.png?h=83&w=200&hash=53CF7E4F89DBDAAE5DFBC912573DDD13",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_generic-content-images/rosette_wis__.png?h=75&w=170&hash=2C0F066D8194A52A34EF9ABC6B784C9B",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/cobis_new.png?h=85&w=170&hash=A0ED03F3476949A30A7DDB9308F486F4",
  "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_design-images/bsme-logo-footer.png?h=40&w=250&hash=B1D88CEB8BA321260538A7A58D9ECC04",
];

export const dummySocials = [
  {
    id: 1,
    name: "facebook",
    link: "https://www.facebook.com/GulfModelSchool.Official",
    url: "https://www.facebook.com/GulfModelSchool.Official",
    image: iconFacebook,
    status: 1,
    created_at: null,
    updated_at: null,
  },
  {
    id: 2,
    name: "instagram",
    link: "https://www.instagram.com/gulfmodelschool/",
    url: "https://www.instagram.com/gulfmodelschool/",
    image: iconInstagram,
    status: 1,
    created_at: null,
    updated_at: null,
  },
  {
    id: 3,
    name: "twitter",
    link: "https://x.com/Gulfmodeldubai",
    url: "https://x.com/Gulfmodeldubai",
    image: iconTwitter,
    status: 1,
    created_at: null,
    updated_at: null,
  },
];

export const dummyCategories = [
  {
    id: 1,
    name: "aboutUs",
    image: "",
    route: "",
    categories: [
      {
        id: 7,
        name: AppPages.AboutUsPage.name,
        image: "",
        route: AppPages.AboutUsPage.route,
      },
      {
        id: 8,
        name: AppPages.WelcomeMessagePage.name,
        image: "",
        route: AppPages.WelcomeMessagePage.route,
      },
      {
        id: 9,
        name: AppPages.VisionMissionValuesPage.name,
        image: "",
        route: AppPages.VisionMissionValuesPage.route,
      },
      // {
      //   id: 10,
      //   name: "Our Team",
      //   image: "",
      //   route: "",
      //   categories: [
      //     {
      //       id: 58,
      //       name: "Executive Team",
      //       image: "",
      //       route: "",
      //     },
      //     {
      //       id: 59,
      //       name: "Senior Leadership Team",
      //       image: "",
      //       route: "",
      //     },
      //     {
      //       id: 60,
      //       name: "Curriculum Leadership Team",
      //       image: "",
      //       route: "",
      //     },
      //     {
      //       id: 61,
      //       name: "Heads of Year",
      //       image: "",
      //       route: "",
      //     },
      //     {
      //       id: 62,
      //       name: "Front of House Team",
      //       image: "",
      //       route: "",
      //     },
      //   ],
      // },
      {
        id: 11,
        name: AppPages.CampusFacilitiesPage.name,
        image: "",
        route: AppPages.CampusFacilitiesPage.route,
      },
      // {
      //   id: 12,
      //   name: "Our Community",
      //   image: "",
      //   route: "",
      //   categories: [
      //     {
      //       id: 65,
      //       name: "Parent Association",
      //       image: "",
      //       route: "",
      //     },
      //     {
      //       id: 66,
      //       name: "GMS Alumni",
      //       image: "",
      //       route: "",
      //     },
      //   ],
      // },
      {
        id: 13,
        name: AppPages.AcademicCalenderPage.name,
        image: "",
        route: AppPages.AcademicCalenderPage.route,
      },
      {
        id: 14,
        name: AppPages.KeyDocumentsPoliciesPage.name,
        image: "",
        route: AppPages.KeyDocumentsPoliciesPage.route,
      },
      // {
      //   id: 15,
      //   name: "Working With Us",
      //   image: "",
      //   route: "",
      // },
    ],
  },
  {
    id: 2,
    name: "whyGms",
    image: "",
    route: "",
    categories: [
      {
        id: 16,
        name: AppPages.WhyAcademicPerformancePage.name,
        image: "",
        route: AppPages.WhyAcademicPerformancePage.route,
      },
      {
        id: 17,
        name: AppPages.WhyUniversityDestinationsPage.name,
        image: "",
        route: AppPages.WhyUniversityDestinationsPage.route,
      },
      // {
      //   id: 18,
      //   name: "Accreditations",
      //   image: "",
      //   route: "",
      // },
      {
        id: 19,
        name: AppPages.HighPerformanceLearningPage.name,
        image: "",
        route: AppPages.HighPerformanceLearningPage.route,
      },
      {
        id: 20,
        name: AppPages.InspectionReportsPage.name,
        image: "",
        route: AppPages.InspectionReportsPage.route,
      },
      // {
      //   id: 21,
      //   name: "Parent Testimonials",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 22,
      //   name: "The GMS Difference",
      //   image: "",
      //   route: "",
      // },
    ],
  },
  {
    id: 3,
    name: "admissions",
    image: "",
    categories: [
      {
        id: 23,
        name: "Virtual Admission Centre",
        image: "",
        route: "",
      },
      {
        id: 24,
        name: AppPages.AdmissionsOverviewPage.name,
        image: "",
        route: AppPages.AdmissionsOverviewPage.route,
      },
      {
        id: 25,
        name: AppPages.AdmissionsProcessPage.name,
        image: "",
        route: AppPages.AdmissionsProcessPage.route,
      },
      {
        id: 26,
        name: AppPages.AgeRequirementsGradePlacementsPage.name,
        image: "",
        route: AppPages.AgeRequirementsGradePlacementsPage.route,
      },
      // {
      //   id: 27,
      //   name: "Tuition Fees",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 28,
      //   name: "Scholarships",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 29,
      //   name: "Book a Tour",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 30,
      //   name: "Enroll Online",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 31,
      //   name: "Transfer Certificate",
      //   image: "",
      //   route: "",
      // },
    ],
  },
  {
    id: 4,
    name: "Curriculum",
    image: "",
    route: "",
    categories: [
      // {
      //   id: 32,
      //   name: "Learning Overview",
      //   image: "",
      //   route: "",
      // },
      {
        id: 33,
        name: AppPages.LowerSchoolPage.name,
        image: "",
        route: AppPages.LowerSchoolPage.route,
      },
      {
        id: 34,
        name: AppPages.MiddleSchoolPage.name,
        image: "",
        route: AppPages.MiddleSchoolPage.route,
      },
      {
        id: 35,
        name: AppPages.UpperSchoolPage.name,
        image: "",
        route: AppPages.UpperSchoolPage.route,
      },
    ],
  },
  {
    id: 5,
    name: AppPages.StudentLifePage.name,
    image: "",
    route: AppPages.StudentLifePage.route,
    categories: [
      // {
      //   id: 39,
      //   name: "Latest Stories",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 40,
      //   name: "Student Leadership",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 41,
      //   name: "Explore Enrich Excite",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 42,
      //   name: "Wellbeing",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 43,
      //   name: "Innovation",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 44,
      //   name: "Rewards",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 45,
      //   name: "Duke of Edinburgh International Award",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 46,
      //   name: "Creativity, Activity & Service",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 47,
      //   name: "External Extra Curricular Activities",
      //   image: "",
      //   route: "",
      // },
    ],
  },
  {
    id: 67,
    name: AppPages.EventsPage.name,
    image: "",
    route: AppPages.EventsPage.route,
  },
  {
    id: 6,
    name: "For Parents",
    image: "",
    route: "",
    categories: [
      {
        id: 48,
        name: AppPages.OurClinicPage.name,
        image: "",
        route: AppPages.OurClinicPage.route,
      },
      // {
      //   id: 49,
      //   name: "Parent Association",
      //   image: "",
      //   route: "",
      // },
      {
        id: 50,
        name: AppPages.AcademicCalenderPage.name,
        image: "",
        route: AppPages.AcademicCalenderPage.route,
      },
      {
        id: 51,
        name: AppPages.OurTransportPage.name,
        image: "",
        route: AppPages.OurTransportPage.route,
      },
      //
      {
        id: 54,
        name: AppPages.SchoolUniformPage.name,
        image: "",
        route: AppPages.SchoolUniformPage.route,
      },
      // {
      //   id: 55,
      //   name: "A Note From The Founder",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 56,
      //   name: "GMS Rewards",
      //   image: "",
      //   route: "",
      // },
      // {
      //   id: 57,
      //   name: "Feedback & Suggestions",
      //   image: "",
      //   route: "",
      // },
    ],
  },
];

export const dummyNotifications = [
  {
    id: 1,
    title: "Order #321",
    body: "Ready for shipment",
    created_at: "2024-10-22T22:43:59.000000Z",
  },
  {
    id: 2,
    title: "Order #134",
    body: "Cancelled by admin",
    created_at: "2024-10-22T22:43:59.000000Z",
  },
];

export const dummyHomeCategories = [
  {
    id: 1,
    name: "Academic Performance",
    description: "Our academic results over the years",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/academic-performance-tn.jpg?h=400&w=490&hash=5CC0588FA79ED2A4BC371408CABC1B1A",
  },
  {
    id: 2,
    name: "University Destinations",
    description:
      "GMS students are accepted by some of the world's most prestigious universities",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/misc/uni-destinations-thumb.jpg?h=400&w=490&hash=016FC76FC74A30EC23E23EA1F62221F8",
  },
  {
    id: 3,
    name: "School Awards",
    description: "Celebrating the various awards and success at GMS",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/explore-enrich-excite/duke-of-edinburgh/490-x400/dsc_1867.png?h=400&w=490&hash=135024AB5AC18587B69B0942B321E79D",
  },
  {
    id: 4,
    name: "High Performance Learning",
    description: "A research-based pedagogy-led philosophy",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/hpl-tn.jpg?h=400&w=490&hash=201739059DC93D3CCA08A553F440EBF7",
  },
  {
    id: 5,
    name: "Outstanding Since 2009",
    description: "GMS is rated Outstanding by DSIB since 2009",
    image:
      "https://www.wellingtoninternationalschool.com/-/media/project/gems/wis_gems_wellington_international_school/_page-thumbnails/general-photos-490-x400/school-1.png?h=400&w=490&hash=F492DACA425A00A7C1500E6BC497D854",
  },
];

export const dummyHomeResults = [
  {
    number: "45",
    text: "Points achieved by five students putting them in the top 0.1% worldwide",
  },
  {
    number: "38",
    text: "Average points achieved by Diploma Students. An impressive result as the world average point score is 30",
  },
  {
    number: "35%",
    text: "Of students achieved 40 points or higher",
  },
  {
    number: "71%",
    text: "Of students achieved 35 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
  {
    number: "99%",
    text: "Of students achieved 30 points or higher",
  },
];

export const dummyHomeSchools = [
  {
    id: 1,
    name: AppPages.LowerSchoolPage.name,
    route: AppPages.LowerSchoolPage.route,
    description: AppPages.LowerSchoolPage.description,
    image: imageHomeLowerSchool,
  },
  {
    id: 2,
    name: AppPages.MiddleSchoolPage.name,
    route: AppPages.MiddleSchoolPage.route,
    description: AppPages.MiddleSchoolPage.description,
    image: imageHomeMiddleSchool,
  },
  {
    id: 3,
    name: AppPages.UpperSchoolPage.name,
    route: AppPages.UpperSchoolPage.route,
    description: AppPages.UpperSchoolPage.description,
    image: imageHomeUpperSchool,
  },
];

export const dummyDocuments = [
  {
    name: "Assessment Policy",
    url: "/documents/assessment_policy.pdf",
  },
  {
    name: "Code of Conduct",
    url: "/documents/code_of_conduct.pdf",
  },
  {
    name: "KHDA Inspection Report 2023-2024",
    url: "/documents/khda_inspection.pdf",
  },
  {
    name: "Action Plan 2024-2025",
    url: "/documents/action_plan.pdf",
  },
  {
    name: "Board Exam Results 2024",
    url: "/documents/board_exam.pdf",
  },
  {
    name: "Employee Attendance, Leave and Disciplinary Policy",
    url: "/documents/employee_policy.pdf",
  },
  {
    name: "Inclusion Policy",
    url: "/documents/inclusion_policy.pdf",
  },
  {
    name: "Inclusion Team",
    url: "/documents/inclusion_team.pdf",
  },
  {
    name: "Statistics About Students with Special Needs",
    url: "/documents/special_needs_statistics.pdf",
  },
  {
    name: "National Child Protection Policy",
    url: "/documents/child_protection_policy.pdf",
  },
];

export const dummyEvents = [
  {
    id: 1,
    location: "Gulf Model School",
    date: "9/Jan/2025",
    description: "event1Description",
    video: event1Video,
  },
];

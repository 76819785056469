import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../../context/app/AppState";
import { basePadding } from "../../../../util/ui_util";

export const ItemEvent = ({ event }) => {
  const { t } = useTranslation();
  const { primaryColor, accentColor } = useContext(AppContext);

return (
    <div className={`flex flex-col-reverse md:flex-row justify-center md:px-0 ${basePadding}`}>
        <div
            className="w-full md:w-1/2 flex flex-col md:justify-between md:px-[5%] py-4"
        >
            <p
                className=""
                dangerouslySetInnerHTML={{ __html: t(event.description) }}
            ></p>
            <div className="flex flex-col md:flex-row justify-between mt-4 md:mt-0">
                <h3 className="font-bold" style={{ color: primaryColor }}>
                    Date: {event.date}
                </h3>
                <h3 className="font-bold" style={{ color: accentColor }}>
                    Location: {event.location}
                </h3>
            </div>
        </div>
        <video className="w-full md:w-[300px] object-cover" controls autoPlay loop>
            <source src={event.video} type="video/mp4" />
            Your browser does not support the video tag.
        </video>
    </div>
);
};

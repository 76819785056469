import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const AgeRequirementGradePlacementsPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.AgeRequirementsGradePlacementsPage.image}
      header={t(AppPages.AgeRequirementsGradePlacementsPage.name)}
      subheader={t("ageRequirementsGradePlacementsPageDescription")}
      text={t("ageRequirementsGradePlacementsText")}
      isHtml={true}
      pages={[AppPages.AdmissionsOverviewPage, AppPages.AdmissionsProcessPage]}
    />
  );
};

import React, { useContext, useState } from "react";
import { ItemNavigationSubcategory } from "./ItemNavigationSubcategory";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ItemNavigation = ({ category, isRtl, flipPosition = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const hasSubcategories =
    category?.categories && category.categories.length > 0;
  const { primaryColor, secondaryColor } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);

  return (
    <div
      className="relative"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div
        className={`flex items-center cursor-pointer text-sm font-bold`}
        style={{ color: isOpen ? secondaryColor : primaryColor }}
        onClick={() => {
          if (category.route) navigate(category.route);
        }}
      >
        {t(category.name)}
      </div>
      {isOpen && hasSubcategories && (
        <div
          className={`absolute w-[250px] z-40 ${
            isRtl
              ? flipPosition
                ? "left-0"
                : "right-0"
              : flipPosition
              ? "right-0"
              : "left-0"
          }`}
        >
          <div className="h-[42px]"></div>
          <div className="bg-navBarDialogBackground shadow-lg w-full">
            {category.categories?.map((sub, index) => (
              <ItemNavigationSubcategory
                key={index}
                category={sub}
                isRtl={isRtl}
                flipPosition={flipPosition}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

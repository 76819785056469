import React, { useContext } from "react";
import { AppContext } from "../../../context/app/AppState";
import { useTranslation } from "react-i18next";
import { AppButton } from "../../base/AppButton";
import { ItemHomeButton } from "./items/ItemHomeButton";
import iconStudent from "../../../assets/images/ic_student.svg";
import iconBook from "../../../assets/images/ic_book.svg";
import iconCalender from "../../../assets/images/ic_calender.svg";
import { useNavigate } from "react-router-dom";
import { AppPages } from "../../../util/app_pages";

export const HomeButtons = () => {
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="flex flex-col w-full bg-backgroundLight dark:bg-backgroundDark py-8">
      <div
        className="flex flex-col w-full items-center py-16"
        style={{ backgroundColor: primaryColor }}
      >
        <h1 className="font-bold text-4xl text-white text-center">
          {t("whyGms")}
        </h1>
        <h1 className="text-white text-center mt-4">
          {t("whyGmsExplanation")}
        </h1>
        <div className="w-[200px] mt-8">
          <AppButton
            text={t("exploreNow").toUpperCase()}
            bgColor={"transparent"}
            bgHoverColor={"transparent"}
            borderColor={"white"}
            borderHoverColor={"white"}
            textColor={"white"}
            textHoverColor={"white"}
            onClick={() => {
              navigate(AppPages.WhyAcademicPerformancePage.route);
            }}
          />
        </div>
      </div>
      <div className="flex flex-col space-y-2 md:flex-wrap md:space-y-0 md:gap-x-4 md:gap-y-2 lg:flex-row lg:flex-nowrap lg:justify-between lg:gap-x-0 lg:gap-y-0 lg:space-x-4 rtl:space-x-reverse mt-8">
        <ItemHomeButton
          text={t("admissions")}
          icon={iconStudent}
          onClick={() => {
            navigate(AppPages.AdmissionsOverviewPage.route);
          }}
        />
        <ItemHomeButton
          text={t("location")}
          icon={iconBook}
          onClick={() => {
            const locationUrl =
              "https://www.google.com/maps/place/Gulf+Model+School/@25.2774406,55.4053305,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f5e8136336665:0x269bf2d8ede22082!8m2!3d25.2774358!4d55.4079054!16s%2Fg%2F124std79l?entry=ttu&g_ep=EgoyMDI1MDEwNi4xIKXMDSoASAFQAw%3D%3D";
            window.open(locationUrl, "_blank");
          }}
        />
        <ItemHomeButton
          text={t("enrolFromHome")}
          icon={iconCalender}
          onClick={() => {}}
        />
      </div>
    </div>
  );
};

import React, { createContext, useReducer, useEffect } from "react";
import AppReducer from "./AppReducer";
import { dummyCategories } from "../../util/dummy";
import {
  getAccentColor,
  getPrimaryColor,
  getSecondaryColor,
} from "../../util/colors";
// import { useSnackbar } from "../general/SnackbarContext";
// import { useTranslation } from "react-i18next";
// import { useNavigate } from "react-router-dom";

// Initial state
const initialState = {
  primaryColor: getPrimaryColor(),
  secondaryColor: getSecondaryColor(),
  accentColor: getAccentColor(),
  language: "en",
  isDarkMode: false,
  stopScroll: false,
  showLoginDialog: false,
  isAuthenticated: false,
  navigationCategories: {
    data: dummyCategories,
    loading: false,
    error: null,
  },
  user: {
    data: null,
    loading: false,
    error: null,
  },
};

const getInitialState = () => {
  const savedState = localStorage.getItem("authState");
  const parsedState = savedState ? JSON.parse(savedState) : {};
  return { ...initialState, ...parsedState };
};

// Create context
export const AppContext = createContext(initialState);

// Provider component
export const AppProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, getInitialState());
  //const { showSuccess, showError } = useSnackbar();
  //const { t } = useTranslation();
  //const navigate = useNavigate();

  // Save state to localStorage
  useEffect(() => {
    const {
      primaryColor,
      secondaryColor,
      accentColor,
      stopScroll,
      showLoginDialog,
      user,
      navigationCategories,
      ...stateToPersist
    } = state;
    localStorage.setItem("authState", JSON.stringify(stateToPersist));
  }, [state]);

  // Actions
  function setLanguage(value) {
    dispatch({
      type: "SET_LANGUAGE",
      payload: value,
    });
  }

  function setIsDarkMode(value) {
    dispatch({
      type: "SET_IS_DARK_MODE",
      payload: value,
    });
  }

  function setStopScroll(value) {
    dispatch({
      type: "SET_STOP_SCROLL",
      payload: value,
    });
  }

  function setShowLoginDialog(value) {
    dispatch({
      type: "SET_SHOW_LOGIN_DIALOG",
      payload: value,
    });
  }

  return (
    <AppContext.Provider
      value={{
        primaryColor: state.primaryColor,
        secondaryColor: state.secondaryColor,
        accentColor: state.accentColor,
        language: state.language,
        isDarkMode: state.isDarkMode,
        stopScroll: state.stopScroll,
        navigationCategories: state.navigationCategories,
        user: state.user,
        showLoginDialog: state.showLoginDialog,
        isAuthenticated: state.isAuthenticated,
        token: state.token,
        isRtl: state.language === "ar",
        setLanguage,
        setIsDarkMode,
        setStopScroll,
        setShowLoginDialog,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

import React from "react";
import { useTranslation } from "react-i18next";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const WelcomeMessagePage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.WelcomeMessagePage.image}
      header={t(AppPages.WelcomeMessagePage.name)}
      subheader={t("aboutUsPageSubheader")}
      text={t("welcomeMessageText").replace(/\n/g, "<br /><br />")}
      pages={[
        AppPages.AboutUsPage,
        AppPages.VisionMissionValuesPage,
        AppPages.CampusFacilitiesPage,
      ]}
      isHtml={true}
    />
  );
};

import React, { useContext } from "react";
import { dummySocials } from "../../../util/dummy";
import logo from "../../../assets/images/logo.png";
import { AppContext } from "../../../context/app/AppState";

export const FooterSocials = () => {
  const socials = dummySocials;
  const { primaryColor } = useContext(AppContext);

  return (
    <div className="flex flex-col pt-2 lg:flex-shrink-0 lg:flex-grow-0 lg:basis-[20%]">
      <div className="flex flex-row space-x-4 rtl:space-x-reverse">
        {socials.map((social, index) => (
          <img
            key={index}
            src={social.image}
            alt=""
            className="w-8 h-8 object-fill cursor-pointer"
            onClick={() => {
              window.open(social.url, "_blank");
            }}
          />
        ))}
      </div>
      <div className="items-center">
      <img src={logo} alt="" className="w-44 object-cover mt-12" />
      <h2 className="md:text-xl select-none" style={{ color: primaryColor }}>
        Gulf Model School
      </h2>
      </div>
    </div>
  );
};

import React from "react";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";
import { useTranslation } from "react-i18next";

export const VisionMissionValuesPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.VisionMissionValuesPage.image}
      header={t(AppPages.VisionMissionValuesPage.name)}
      subheader={""}
      text={t("visionMissionValuesText")}
      pages={[
        AppPages.AboutUsPage,
        AppPages.WelcomeMessagePage,
        AppPages.CampusFacilitiesPage,
      ]}
      isHtml={true}
    />
  );
};

import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";

export const MiddleSchoolPage = () => {
  const { t } = useTranslation();
  const { primaryColor, accentColor } = useContext(AppContext);

  return (
    <InfoPageLayout
      imageUrl={AppPages.MiddleSchoolPage.image}
      header={t(AppPages.MiddleSchoolPage.name)}
      subheader={t("middleSchoolPageSubheader")}
      pages={[AppPages.LowerSchoolPage, AppPages.UpperSchoolPage]}
      children={
        <div className="mt-8">
          <p className="text-textLight dark:text-textDark">
            {t("middleSchoolText1")}
          </p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("middleSchoolText2")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p className="text-textLight dark:text-textDark mt-4">
            {t("middleSchoolText3")}
          </p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("middleSchoolText4")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("middleSchoolText5") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("middleSchoolText6")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("middleSchoolText7") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("middleSchoolText8")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("middleSchoolText9") }}
          ></p>
          <h2
            className="mt-8 font-bold text-2xl"
            style={{ color: primaryColor }}
          >
            {t("middleSchoolText10")}
          </h2>
          <div
            className="w-full h-[2px] mt-2"
            style={{ backgroundColor: accentColor }}
          ></div>
          <p
            className="text-textLight dark:text-textDark mt-4"
            dangerouslySetInnerHTML={{ __html: t("middleSchoolText11") }}
          ></p>
        </div>
      }
    />
  );
};

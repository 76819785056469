import React, { useEffect, useState } from "react";
import { spinner } from "../../util/ui_util";

const PdfViewer = ({ pdfUrl }) => {
  const [pages, setPages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadPdf = async () => {
      const pdfjsLib = await import("pdfjs-dist/build/pdf");
      pdfjsLib.GlobalWorkerOptions.workerSrc =
        window.location.origin + "/pdf.worker.min.mjs";
      const loadingTask = pdfjsLib.getDocument(pdfUrl);
      const pdf = await loadingTask.promise;
      const numPages = pdf.numPages;
      const pageImages = [];

      for (let pageNum = 1; pageNum <= numPages; pageNum++) {
        const page = await pdf.getPage(pageNum);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        const renderContext = {
          canvasContext: context,
          viewport: viewport,
        };

        await page.render(renderContext).promise;
        pageImages.push(canvas.toDataURL());
      }

      setPages(pageImages);
      setLoading(false);
    };

    loadPdf();
  }, [pdfUrl]);

  return (
    <div className="pdf-viewer">
      {loading
        ? spinner()
        : pages.map((page, index) => (
            <img
              key={index}
              src={page}
              alt={`Page ${index + 1}`}
              className="pdf-page"
            />
          ))}
    </div>
  );
};

export default PdfViewer;

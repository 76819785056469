import React from "react";
import { InfoPageLayout } from "../../base/InfoPageLayout";
import { AppPages } from "../../../util/app_pages";
import { useTranslation } from "react-i18next";

export const SchoolUniformPage = () => {
  const { t } = useTranslation();

  return (
    <InfoPageLayout
      imageUrl={AppPages.SchoolUniformPage.image}
      header={t(AppPages.SchoolUniformPage.name)}
      subheader={t("schoolUniformPageSubheader")}
      text={t("schoolUniformText")}
      isHtml={true}
      pages={[AppPages.OurClinicPage, AppPages.OurTransportPage]}
    />
  );
};

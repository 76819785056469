import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import { basePadding, PurpleDivider } from "../../../util/ui_util";
import { AppButton } from "../../base/AppButton";
import { useNavigate } from "react-router-dom";
import { AppPages } from "../../../util/app_pages";
import imageWelcomeMessage from "../../../assets/images/home_welcome_message.png";

export const HomePrincipalMessage = () => {
  const { primaryColor, secondaryColor, accentColor } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className={`w-full bg-[#F4F7F7] py-8 ${basePadding} md:px-0`}>
      <div className="container mx-auto flex flex-col-reverse md:flex-row w-full space-y-4 space-y-reverse md:space-y-0 md:justify-between md:space-x-16 md:rtl:space-x-reverse">
        <div className="flex flex-col md:w-1/2 lg:w-[60vw]">
          <h4 className="font-bold text-xl" style={{ color: primaryColor }}>
            {t("welcomeMessage")}
          </h4>
          <h2
            className="font-bold text-3xl mt-4"
            style={{ color: primaryColor }}
          >
            {t("fromThePrincipal")}
          </h2>
          <div className="mt-6">
            <PurpleDivider />
          </div>
          <p className="text-textLight font-bold mt-4">{t("principalName")}</p>
          <p className="text-textLight">{t("principalDescription")}</p>
          <div className="w-[200px] mt-12">
            <AppButton
              text={t("readMore")}
              bgColor={"transparent"}
              bgHoverColor={"transparent"}
              borderColor={primaryColor}
              borderHoverColor={secondaryColor}
              textColor={primaryColor}
              textHoverColor={secondaryColor}
              onClick={() => {
                navigate(AppPages.WelcomeMessagePage.route);
              }}
            />
          </div>
        </div>
        <div className="relative md:w-1/2 lg:w-[40vw] h-[450px]">
          <div
            className="absolute w-full h-full top-[5px] left-[5px] hidden"
            style={{ backgroundColor: accentColor }}
          ></div>
          <img
            src={imageWelcomeMessage}
            alt=""
            className="absolute h-full w-full bottom-[5px] right-[5px] object-cover object-center"
          ></img>
        </div>
      </div>
    </div>
  );
};

import React from 'react'
import { useTranslation } from 'react-i18next';
import { InfoPageLayout } from '../../base/InfoPageLayout';
import { AppPages } from '../../../util/app_pages';

export const CampusFacilitiesPage = () => {
  const { t } = useTranslation();
  
    return (
      <InfoPageLayout
        imageUrl={AppPages.CampusFacilitiesPage.image}
        header={t(AppPages.CampusFacilitiesPage.name)}
        subheader={""}
        text={t("campusFacilitiesText")}
        pages={[
          AppPages.AboutUsPage,
          AppPages.WelcomeMessagePage,
          AppPages.VisionMissionValuesPage,
        ]}
      />
    );
}

import React, { useContext, useState } from "react";
import { AppButton } from "../../../base/AppButton";
import { AppContext } from "../../../../context/app/AppState";
import { addOpacityToHex } from "../../../../util/ui_util";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

export const ItemHomeCategory = ({ category }) => {
  const [isHovered, setIsHovered] = useState(false);
  const { primaryColor } = useContext(AppContext);
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleMouseEnter = () => setIsHovered(true);
  const handleMouseLeave = () => setIsHovered(false);

  return (
    <div
      className="relative flex-none w-full md:w-[400px] h-[400px] bg-cover bg-center overflow-hidden"
      style={{
        backgroundImage: `url(${category.image})`,
      }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="absolute inset-0 bg-appPurple opacity-50"></div>
      <div
        className={`absolute inset-0 flex items-center justify-center text-white text-2xl text-center font-bold transition-all duration-500 select-none ${
          isHovered ? "translate-y-[-150px]" : "translate-y-0"
        }`}
      >
        {t(category.page.name)}
      </div>
      <div
        className={`absolute bottom-4 flex flex-col px-16 items-center justify-center transition-all duration-500 ${
          isHovered
            ? "translate-y-[-20px] opacity-100"
            : "translate-y-0 opacity-0"
        }`}
      >
        <div className="text-white text-lg text-center pb-[120px] select-none">
          {t(category.page.description)}
        </div>
        <AppButton
          text={t("readMore")}
          bgColor={addOpacityToHex(primaryColor, 0.8)}
          bgHoverColor={addOpacityToHex(primaryColor, 0.8)}
          borderColor={"white"}
          borderHoverColor={"white"}
          textColor={"white"}
          textHoverColor={"white"}
          onClick={() => navigate(category.page.route)}
        />
      </div>
    </div>
  );
};

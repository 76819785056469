import React, { useState, useEffect, useContext } from "react";
import { NavigationBar } from "./NavigationBar";
import { Footer } from "./footer/Footer";
import { Drawer } from "./Drawer";
import { AppContext } from "../../context/app/AppState";
import bg from "../../assets/images/layout_bg.jpg";

export const Layout = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false);
  const [isBurgerMenuOpen, setIsBurgerMenuOpen] = useState(false);
  const [isMoreOpen, setIsMoreOpen] = useState(false);

  const { navigationCategories, stopScroll, isRtl } = useContext(AppContext);

  const handleScroll = () => {
    if (window.scrollY > 150) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
      setIsMoreOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    document.body.style.overflow =
      isBurgerMenuOpen || stopScroll ? "hidden" : "auto";
  }, [isBurgerMenuOpen, stopScroll]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 1024px)");
    const handleMediaQueryChange = (event) => {
      if (event.matches) {
        setIsBurgerMenuOpen(false);
      }
    };
    mediaQuery.addEventListener("change", handleMediaQueryChange);
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="relative max-w-screen overflow-x-hidden min-h-screen">
      {/* Background */}
      <div
        className="fixed inset-0 w-full h-full bg-cover bg-center bg-no-repeat -z-10"
        style={{
          backgroundImage: `url(${bg})`,
          backgroundAttachment: "fixed",
        }}
      ></div>

      {/* Content */}
      <div className="relative flex flex-col w-full min-h-screen">
        {/* Sticky Navbar */}
        <div
          className={`fixed top-0 left-0 w-full bg-white shadow-md z-30 transition-transform duration-300 transform ${
            isSticky ? "translate-y-0" : "-translate-y-full"
          }`}
        >
          {navigationCategories && !navigationCategories.loading ? (
            <NavigationBar
              isBurgerMenuOpen={isBurgerMenuOpen}
              isMoreOpen={isMoreOpen}
              setIsMoreOpen={setIsMoreOpen}
              categories={navigationCategories.data}
              showMore={true}
              onBurgerMenuClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
            />
          ) : null}
        </div>

        {/* Main Content */}
        <div className="flex flex-grow flex-col">
          {navigationCategories && !navigationCategories.loading ? (
            <NavigationBar
              isBurgerMenuOpen={isBurgerMenuOpen}
              isMoreOpen={isMoreOpen}
              setIsMoreOpen={setIsMoreOpen}
              categories={navigationCategories.data}
              onBurgerMenuClick={() => setIsBurgerMenuOpen(!isBurgerMenuOpen)}
            />
          ) : (
            <div className="h-12"></div>
          )}
          <main>{children}</main>
        </div>

        {/* Footer */}
        <Footer isRtl={isRtl} />

        {/* Drawer */}
        {navigationCategories && !navigationCategories.loading ? (
          <Drawer
            isOpen={isBurgerMenuOpen}
            isRtl={isRtl}
            allCategories={navigationCategories.data}
            onCloseClick={() => setIsBurgerMenuOpen(false)}
          />
        ) : null}
      </div>
    </div>
  );
};

import React, { useContext } from "react";
import { Layout } from "../../base/Layout";
import { dummyEvents } from "../../../util/dummy";
import { useTranslation } from "react-i18next";
import { AppContext } from "../../../context/app/AppState";
import { AppPages } from "../../../util/app_pages";
import { PurpleDivider } from "../../../util/ui_util";
import { ItemEvent } from "./items/ItemEvent";

export const EventsPage = () => {
  const events = dummyEvents;
  const { t } = useTranslation();
  const { primaryColor } = useContext(AppContext);

  return (
    <Layout>
      <div className="w-full bg-backgroundLight dark:bg-backgroundDark py-8">
        <div className="flex flex-col items-center">
          <div
            className="flex flex-row w-full justify-center font-bold text-xl md:text-3xl mt-8"
            style={{ color: primaryColor }}
          >
            {t(AppPages.EventsPage.name)}
          </div>
          <div className="mt-4">
            <PurpleDivider />
          </div>
          <ul className="w-full flex flex-col mt-12 space-y-4">
            {events.map((event, index) => (
              <ItemEvent key={index} event={event} />
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};
